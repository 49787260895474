import {
  Tolgee,
  DevTools,
  FormatSimple,
  BackendFetch,
  type TolgeeStaticData,
  type TreeTranslationsData,
} from '@tolgee/vue'
import { FormatIcu } from '@tolgee/format-icu'
import editorTranslations from '@/i18n/en.editor.json'
import en from '@/i18n/en.json'
import de from '@/i18n/de.json'
import fr from '@/i18n/fr.json'
import es from '@/i18n/es.json'
import enUS from '@/i18n/en-US.json'
import nl from '@/i18n/nl.json'
import { type LanguageType, type LocaleType } from '@/api/interfaces'
import { LOCALES } from '@/api/interfaces'

export const LS_LANGUAGE_KEY = 'user-locale'

export const languages: Record<LanguageType, string> = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  nl: 'Hollands',
}

const editorStaticData: TolgeeStaticData = {
  'en:local': editorTranslations,
}

const staticData: TolgeeStaticData = {
  ...editorStaticData,
  en,
  de,
  fr,
  es,
  'en-US': enUS,
  nl,
} satisfies Record<LocaleType, TreeTranslationsData>

const tolgee = Tolgee().use(DevTools()).use(FormatSimple()).use(FormatIcu())

// use the CDN for translations in deploy preview environments
export const isDeployPreviewEnvironment = window.location.hostname.startsWith('deploy-preview')
if (isDeployPreviewEnvironment) {
  tolgee.use(BackendFetch({ prefix: import.meta.env.VITE_TOLGEE_CDN }))
}

export default tolgee.init({
  availableLanguages: [...LOCALES],
  language: import.meta.env.VITE_DEFAULT_LOCALE,
  staticData: isDeployPreviewEnvironment ? editorStaticData : staticData,
})
